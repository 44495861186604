import { PartOfSpeech, Position, QueryParams, templateKey, toCacheKey } from "./models/Query";

import QUERIES from './queries.json';

// eslint-disable-next-line
function jsonToQueryParams(obj: any): QueryParams {
  return {
    query: obj["query"],
    partOfSpeech: PartOfSpeech[obj.partOfSpeech as keyof typeof PartOfSpeech],
    position: Position[obj.position as keyof typeof Position]
  };
}

const AUTOCOMPLETE_CHOICES = QUERIES.queries.map(jsonToQueryParams);

const CACHED_QUERY_SET: Set<string> = new Set();

export function isInStaticCache(queryParams: QueryParams): boolean {
  const cacheKey = toCacheKey(queryParams);
  return CACHED_QUERY_SET.has(cacheKey);
}

const PER_TEMPLATE_CHOICES: Map<string, Array<QueryParams>> = new Map([
  ["NOUN_PHRASE_AFTER", []],
  ["ADJECTIVE_AFTER", []],
  ["NOUN_PHRASE_BEFORE", []],
  ["ADJECTIVE_BEFORE", []],
]);


for (const choice of AUTOCOMPLETE_CHOICES) {
  const key = templateKey(choice);
  PER_TEMPLATE_CHOICES.get(key)?.push(choice);
  CACHED_QUERY_SET.add(toCacheKey(choice));
}


export function getAutocompleteChoices(queryParams: QueryParams): Array<QueryParams> {
  const key = templateKey(queryParams);
  const values = PER_TEMPLATE_CHOICES.get(key);
  if (values == undefined) {
    return [];
  }
  return values;
}

