interface SidebarProps {
  onCloseClick(): void;
}

export function Sidebar(props: SidebarProps): JSX.Element {
  return (
    <div className="flex-column bg-light mb-5 pb-5 sidebar">
      <button className="close btn" onClick={props.onCloseClick}><i className="bi bi-x-circle"></i></button>
      <div>
        <h5 className="fw-bold">What is this?</h5>
        <p>
          This app searches through 1 billion web pages to extract the most common occurrences of a given pattern and builds the corresponding word cloud. Type the beginning of a sentence and let the web complete it.
        </p>
      </div>
      <div className="mb-3">
        <h5 className="fw-bold">How do we do this? </h5>
        <p>
          We used our <a href="https://quickwit.io" target="_blank">search engine</a> to index 1 billion English web pages from <a href="https://commoncrawl.org/">Common Crawl</a>.
          The index is stored on Amazon S3 and weighs 6.8 TB.
          We use 2 stateless Amazon EC2 instances to query that index and return the matching query phrases in a few seconds.
          Then, we post-process the results to select the relevant adjectives or noun phrases and keep track of the most common occurrences.
        </p>
        <p>
          As a consequence, the latency you observe is the sum of the search latency and the post-processing latency.
        </p>        
        <p>
          Our search backend is developed in Rust whereas our post-processing logic is implemented in Python and relies on <a href="https://github.com/clips/pattern" target="_blank">Pattern</a> for Natural Language Processing.
          Finally, each “microservice” runs on Kubernetes.
        </p>
        <p>
          Check out our <a href="https://quickwit.io/blog/common-crawl" target="_blank">technical blog post</a> for a detailed explanation.
        </p>
      </div>
      <div className="mb-3">
        <h5 className="fw-bold">Who are we?</h5>
        <p>We’re the people behind Quickwit, company founded in late 2020. We wanted to build something fun to showcase what we’ve been working on.</p>
        <p>In the next few weeks, we will start open-sourcing the Quickwit search engine and we hope you’ll enjoy using and contributing to it.
          Keep an eye on our <a href="https://github.com/quickwit-inc/quickwit" target="_blank">public GitHub repository</a>. In the meantime, you can contact us directly at hello@quickwit.io.
        </p>
      </div>
      <div className="mb-3">
        <h5 className="fw-bold">How much does this cost?</h5>
        <p>
          While you can already query large indexes stored on Amazon S3 with UltraWarm for Amazon Elasticsearch Service or
          Elasticsearch searchable snapshots, only Quickwit can do so with very little resources and lower latency:
        </p>
        <p>
          <ul>
            <li>
              Storage: <b>$160/month</b><br/>
              6.8 TB on Amazon S3
            </li>
            <li>
              Search compute: <b>$650/month</b><br/>
              2 Amazon EC2 c5n.2xlarge
            </li>
            <li>
              Indexing compute: <b>$45</b><br/>
              1 Amazon EC2 m5d.8xlarge for 24 hours
            </li>
          </ul>
        </p>
      </div>
      <div className="mb-3">
        <h5 className="fw-bold">What data?</h5>
        <p>
          The data comes from the <a href="https://commoncrawl.org/2021/03/february-march-2021-crawl-archive-now-available/" target="_blank">Common Crawl Foundation</a> who provides regular snapshots of billions of web pages.
          We downloaded the latest common crawl archive dating from February/March and indexed the text data from English pages only. It represents roughly
          1 billion pages and 7 TB.
        </p>
      </div>
      <div className="mb-3 text-center fw-bold">
        <a href="https://quickwit.io/blog/commoncrawl" target="_blank" className="btn btn-outline-secondary">
          <span className="me-2">More on our blog post</span>
          <i className="bi bi-plus"></i>
        </a>
      </div>
    </div>
  )
}
