export enum PollingState {
  Inactive,
  Fetch,
  Active,
}

export enum ResultState {
  Empty,
  FirstFetch,
  NotEnoughResult,
  Partial,
  Complete,
  Error,
  PartialWithError,
}