import { PartOfSpeech, Position, QueryParams } from "./models/Query";

export function parseUrl(historySearch: string): QueryParams {
  const searchParams = new URLSearchParams(historySearch);
  return {
    query: searchParams.get("query") || "",
    partOfSpeech: searchParams.get("partOfSpeech") == "NOUN_PHRASE" ? PartOfSpeech.NOUN_PHRASE : PartOfSpeech.ADJECTIVE,
    position: searchParams.get("position") == "BEFORE" ? Position.BEFORE : Position.AFTER,
  };
}

export function toUrlQueryParams(queryParams: QueryParams): URLSearchParams {
  const params = new URLSearchParams();
  params.append("query", queryParams.query);
  params.append("partOfSpeech", PartOfSpeech[queryParams.partOfSpeech]);
  params.append("position", Position[queryParams.position]);
  return params;
}

export function queryParamsToText(queryParams: QueryParams): string {
  if (queryParams.position == Position.BEFORE) {
    return `... ${queryParams.query}.`;
  } else {
    return `${queryParams.query}... `;
  }
}
