import { PartOfSpeech, Position, QueryParams, toCacheKey } from "../models/Query";
import { queryParamsToText } from "../utils";

interface CardProps {
  queryParams: QueryParams;
  onViewClick(queryParams: QueryParams): void;
}

function Card(props: CardProps): JSX.Element {
  const url = `/svg/${toCacheKey(props.queryParams).replaceAll(" ", "_")}.svg`;
  return (
    <div className="card me-3 mb-3" style={{ width: "280px" }}>
      <div className="card-body">
        <p className="card-text" style={{ fontSize: "22px", fontWeight: 700 }}>
            {queryParamsToText(props.queryParams)}
        </p>
      </div>
      <a
        type="button"
        className="btn btn-share btn-lg"
        style={{
          width: "92px",
          position: "absolute",
          right: "0px",
          bottom: "10px",
          justifyContent: "center",
        }}
        href="#tagcloud"
        onClick={() => props.onViewClick(props.queryParams)}
      >
        <span>View</span>
      </a>
      <img
        className="card-img-bottom"
        src={url}
        alt="Card image cap"
      />
    </div>
  );
}

interface GalleryProps {
    onViewClick(queryParams: QueryParams): void;
}

export function Gallery(props: GalleryProps): JSX.Element {
  const data: QueryParams[] = [
    {
        query: "Barack Obama is",
        partOfSpeech: PartOfSpeech.ADJECTIVE,
        position: Position.AFTER,
    },
    {
        query: "French people are",
        partOfSpeech: PartOfSpeech.ADJECTIVE,
        position: Position.AFTER,
    },
    {
          query: "Google is",
          partOfSpeech: PartOfSpeech.NOUN_PHRASE,
          position: Position.AFTER,
    },
    {
        query: "elasticsearch is",
        partOfSpeech: PartOfSpeech.ADJECTIVE,
        position: Position.AFTER,
    },
    {
        query: "I work as a",
        partOfSpeech: PartOfSpeech.NOUN_PHRASE,
        position: Position.AFTER,
    },
    {
        query: "is the future",
        partOfSpeech: PartOfSpeech.NOUN_PHRASE,
        position: Position.BEFORE,
    },
    {
          query: "is the new oil",
          partOfSpeech: PartOfSpeech.NOUN_PHRASE,
          position: Position.BEFORE,
    },
    {
          query: "is the best os",
          partOfSpeech: PartOfSpeech.NOUN_PHRASE,
          position: Position.BEFORE,
    },
  ];

  return (
    <div className="my-5">
      <div className="display-6 fw-bold mb-5">Most popular word clouds</div>
      <div className="gallery d-flex align-items-start flex-wrap">
        {data.map((queryParams) => <Card queryParams={queryParams} onViewClick={props.onViewClick} />)}
      </div>
    </div>
  );
}
