import { ReactComponent as Logo } from "./../img/logo.svg";

interface NavbarProps {
  onAboutClick(): void;
}

export function Navbar(props: NavbarProps): JSX.Element {
  function handleClick() {
    props.onAboutClick();
  }

  return (
    <nav className="navbar navbar-light mt-2 mt-md-3 mb-md-4">
      <div className="container-fluid">
        <a className="navbar-brand ms-1 ms-sm-2 ms-xxl-5 ms-md-4 icon-spin" href="https://quickwit.io" target="_blank">
          <Logo className="d-inline-block align-text-top" width="30" height="24" fill="#FF0026"></Logo>
        </a>
        <ul className="nav">
          <li className="nav-item mx-0">
            <button className="nav-link" onClick={handleClick}>
              About
            </button>
          </li>
          <li className="nav-item mx-0">
            <a className="nav-link" href="https://www.quickwit.io/blog/commoncrawl">
              Blog post
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="ms-2 mb-1 bi bi-arrow-up-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
