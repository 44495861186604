import React, { useEffect, useRef, useState } from "react";
import { Navbar } from "./components/Navbar";
import { Sidebar } from "./components/Sidebar";
import { Header } from "./components/Header";
import { Tagcloud } from "./components/Tagcloud";
import { Client } from "./ApiClient";
import { SearchResponse } from "./models/SearchResponse";
import { ReactComponent as Logo } from "./img/logo.svg";
import './css/app.scss';
import { ResultState, PollingState } from "./models/Status";
import { PartOfSpeech, Position, QueryParams } from "./models/Query";
import ReactGA from 'react-ga';
import { Gallery } from "./components/Gallery"
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { parseUrl, toUrlQueryParams } from "./utils";

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // Dev stuff...
} else {
  // Enable tracking only in production
  ReactGA.initialize('UA-180671940-2');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const POLLING_TIMEOUT = 15_000;
const MIN_OCCURRENCES_COUNT = 5.

interface ResponseState {
  lastQueryStartTime: number;
  resultState: ResultState;
  response?: SearchResponse;
  error?: string;
}

function Fetching() {
  return (
    <header className="d-flex flex-column loading">
      <div className="mx-auto my-5 py-5">
        <div className="mb-3">
          <Logo className="App-logo" width="10vmin"></Logo>
        </div>
        <div className="text-muted">SEARCHING...</div>
      </div>
    </header>
  );
}

function Error(message: string) {
  return (
    <div className="d-flex flex-column my-5 py-5 error">
      <i className="bi bi-emoji-frown"></i>
      <div className="text-muted">{message}... please retry later</div>
      <div className="text-muted mt-2">In the meantime, take a look at the gallery or select only suggested queries.</div>
    </div>
  );
}

function NotEnoughResult() {
  return (
    <div className="d-flex flex-column my-5 py-5 error">
      <i className="bi bi-emoji-frown"></i>
      <div className="text-muted">Not enough result for this query</div>
      <div className="text-muted mt-2">Try something else!</div>
    </div>
  );
}

function getResultState(response: SearchResponse): ResultState {
  if (response.done && response.count > MIN_OCCURRENCES_COUNT) {
    return  ResultState.Complete;
  } else if (response.done && response.count <= MIN_OCCURRENCES_COUNT) {
    return ResultState.NotEnoughResult;
  } else if (response.count > 5) {
    return ResultState.Partial;
  } else {
    return ResultState.FirstFetch;
  }
}

function sendGaEvent(queryParams: QueryParams) {
  const action = queryParams.position == Position.AFTER ? 
  `${queryParams.query}+${queryParams.partOfSpeech}` : `${queryParams.partOfSpeech}+${queryParams.query}`
  ReactGA.event({
    category: "Query",
    action: action,
  });
}

function App(): JSX.Element {
  const client = new Client();
  const location = useLocation();
  const history = useHistory();
  const [state, setState] = useState<ResponseState>({
    lastQueryStartTime: 0,
    resultState: ResultState.Empty,
  });
  const [queryParams, setQueryParams] = useState<QueryParams>({query: "", partOfSpeech: PartOfSpeech.ADJECTIVE, position: Position.AFTER});
  const [pollingState, setPollingState] = useState<PollingState>(PollingState.Inactive);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(window.innerWidth >= 1200);
  const tagCloudRef = useRef<HTMLDivElement>(null);

  function search(params: QueryParams) {
    setPollingState(PollingState.Fetch);
    client.search(params).then(
      searchResponse => {
        setPollingState(searchResponse.done ? PollingState.Inactive : PollingState.Active);
        setState(previousState => ({
          ...previousState, resultState: getResultState(searchResponse), response: searchResponse}));
      },
      error => setState(previousState => {
        setPollingState(PollingState.Inactive);
        return {...previousState, resultState: ResultState.Error, error: error.toString()};
      }),
    );
  }
  
  useEffect(() => {
    if (pollingState == PollingState.Active && (Date.now() - state.lastQueryStartTime) > POLLING_TIMEOUT) {
      console.log('too long to retrieve result, stop here');
      setPollingState(PollingState.Inactive);
      const resultStatus = state.response && state.response.count > MIN_OCCURRENCES_COUNT ? 
        ResultState.PartialWithError : ResultState.NotEnoughResult;
      setState(previousState => ({...previousState, 
        resultState: resultStatus, 
        error: "too long to retrieve all results"}));
    } else if (pollingState == PollingState.Active) {
      console.log('next polling in 3 seconds...');
      const timer = setTimeout(() => search(queryParams), 3000);
      return () => clearTimeout(timer);
    }
  }, [pollingState]);

  useEffect(() => {
    if (queryParams.query.length > 5) {
      search(queryParams);
    }
  }, [queryParams]);

  useEffect(() => {
    setQueryParams(parseUrl(location.search));
  }, [location]);

  const handleQueryParamsUpdate = (params: QueryParams, scrollToResult=false) => {
    if (scrollToResult) {
      setTimeout(() => {
        tagCloudRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 400);
    }
    sendGaEvent(params);
    setState(previousState => ({...previousState, 
      resultState: ResultState.FirstFetch, 
      lastQueryStartTime: Date.now()}));
    if (params.query == queryParams.query && params.position == queryParams.position && params.partOfSpeech == queryParams.partOfSpeech) {
      search(queryParams);
    } else {
      history.push({ search: "?" + toUrlQueryParams(params).toString() });
    }
  }

  const handleAboutClick = () => {
    setSidebarOpen(!sidebarOpen);
  }

  return (
    <div className="App">
      <Switch>
        <Route path="/">
          <Navbar onAboutClick={handleAboutClick}></Navbar>
          <div className="main-container d-flex">
            <div className="d-flex flex-grow-1 flex-column">
              <Header queryParams={queryParams} resultStatus={state.resultState} onUpdate={handleQueryParamsUpdate}></Header>
              <div ref={tagCloudRef}>
                { [ResultState.PartialWithError, ResultState.Partial, ResultState.Complete].includes(state.resultState)
                  && state.response
                  && <Tagcloud response={state.response} resultState={state.resultState}></Tagcloud> }
                { state.resultState == ResultState.FirstFetch && <Fetching />}
                { state.resultState == ResultState.NotEnoughResult && NotEnoughResult()}
                { state.resultState == ResultState.Error && state.error && Error(state.error) }
                <Gallery onViewClick={params => handleQueryParamsUpdate(params, true)} />
               </div>
            </div>
          </div>
          { sidebarOpen && <Sidebar onCloseClick={handleAboutClick} />}
        </Route>
      </Switch>
    </div>
  );
}

export default App;
