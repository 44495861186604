import { SearchResponse } from "./models/SearchResponse";
import { normalizeQueryText, QueryParams, toCacheKey } from "./models/Query";
import { isInStaticCache } from "./data";

interface ServerResponse {
  occurrences: Array<[string, number]>;
  done: boolean;
  numMicrosecs: number;
}

export class Client {
  private _apiRoot: string;
  private _staticCacheRoot: string;

  constructor() {
    this._apiRoot = `${window.location.protocol}//${window.location.host}/api/search-tags`;
    this._staticCacheRoot = `${window.location.protocol}//${window.location.host}/query_cache/`;
  }

  fetchFromCache(queryParams: QueryParams): Promise<SearchResponse> {
    const url: string =
      this._staticCacheRoot + toCacheKey(queryParams).replaceAll(" ", "_") + ".json";
    const requestParams: RequestInit = {
      method: "GET",
      headers: { Accept: "application/json" },
      mode: "no-cors",
      cache: "default",
    };
    return fetch(url, requestParams).then(response => {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") === -1) {
        return Promise.reject(
          new Error("content type returned by api <> application/json")
        );
      }

      if (response.status != 200) {
        return Promise.reject(new Error("Error returned by server"));
      }

      return response.json();
    }).then((payload: ServerResponse) => {
      return {
        done: payload.done,
        count: payload.occurrences.reduce((accumulator, occurence) => accumulator + occurence[1], 0),
        numMicrosecs: payload.numMicrosecs,
        occurrences: payload.occurrences,
        queryParams: queryParams,
      };
    });

  }

  search(queryParams: QueryParams): Promise<SearchResponse> {
    if (isInStaticCache(queryParams)) {
      return this.fetchFromCache(queryParams);
    }
    const url: URL = new URL(this._apiRoot);
    url.searchParams.append("query", normalizeQueryText(queryParams.query));
    url.searchParams.append("partOfSpeech", queryParams.partOfSpeech.toString());
    url.searchParams.append("position", queryParams.position.toString());

    const requestParams: RequestInit = {
      method: "GET",
      headers: { Accept: "application/json" },
      mode: "no-cors",
      cache: "default",
    };

    return fetch(url.pathname + url.search, requestParams)
      .then(response => {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") === -1) {
          return Promise.reject(
            new Error("content type returned by api <> application/json")
          );
        }

        if ([400, 423].includes(response.status)) {
          return Promise.resolve(response.json())
            .then(json =>  Promise.reject(new Error(json.message)));
        }

        if (!response.ok) {
          return Promise.resolve(response.text())
            .then(text =>  Promise.reject(new Error(text.slice(0, 50))));
        }

        return response.json();
      })
      .then((payload: ServerResponse) => {
        const response = {
          done: payload.done,
          count: payload.occurrences.reduce((accumulator, occurence) => accumulator + occurence[1], 0),
          numMicrosecs: payload.numMicrosecs,
          occurrences: payload.occurrences,
          queryParams: queryParams,
        };
        console.log('response', response);
        return response;
      });
  }
}
