import React, { useEffect } from "react";
import { PartOfSpeech, Position, QueryParams } from "../models/Query";
import { SearchUpdateProps } from "../models/SearchRequest";
import { ResultState } from "../models/Status";
import Autosuggest from 'react-autosuggest';
import { getAutocompleteChoices } from "../data";
import { DragDropContext, Draggable, DraggableProvided, Droppable, DropResult } from "react-beautiful-dnd";


//https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(queryParams: QueryParams) {
  const choices = getAutocompleteChoices(queryParams);
  const escapedValue = escapeRegexCharacters(queryParams.query.trim());
  if (escapedValue === '') {
    return choices;
  }

  const regex = new RegExp(escapedValue, 'i');

  return choices.filter(choice => regex.test(choice.query));
}

function getSuggestionValue(suggestion: QueryParams): string {
  return suggestion.query;
}

function renderSuggestion(suggestion: QueryParams): JSX.Element {
  return (
    <span>{suggestion.query}</span>
  );
}

interface Item {
  id: string;
  content: string;
}

function reorder(list: Item[], startIndex: number, endIndex: number):Item[] {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

interface InputProps extends DraggableProvided, SearchUpdateProps {
  type: string;
}

function DraggableInput(props: InputProps): JSX.Element {
  const [suggestions, setSuggestions] = React.useState<Array<QueryParams>>([]);

  function handleQueryTextChange(queryText: string) {
    props.onUpdate({query: queryText, partOfSpeech: props.queryParams.partOfSpeech, position: props.queryParams.position});
  }

  function handleSelectedSuggestion(choice: QueryParams) {
    props.onUpdate({query: choice.query, partOfSpeech: choice.partOfSpeech, position: props.queryParams.position});
  }

  const onSuggestionsFetchRequested = (query: string) => {
    const queryParams: QueryParams = {query: query, position: props.queryParams.position, partOfSpeech: props.queryParams.partOfSpeech};
    setSuggestions(getSuggestions(queryParams));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  function handlePartOfSpeechChange(partOfSpeechString: string) {
    const typePartOfSpeechString = partOfSpeechString as keyof typeof PartOfSpeech;
    const partOfSpeech = PartOfSpeech[typePartOfSpeechString];
    props.onUpdate({query: props.queryParams.query, partOfSpeech: partOfSpeech, position: props.queryParams.position});
  }

  if (props.type == "select") {
    return <div className="d-flex"
      ref={props.innerRef}
      {...props.draggableProps}
      >
      <i style={{fontSize: "37px"}}
        {...props.dragHandleProps}
        className="bi bi-grip-vertical">
      </i>
      <select
        className="form-select"
        aria-label=".form-select"
        value={props.queryParams.partOfSpeech.toString()}
        onChange={({ target: { value } }) => handlePartOfSpeechChange(value)}
        placeholder="Starts/ends with..."
        required>
        <option disabled>Starts/ends with...</option>
        <option value="ADJECTIVE">Adjective</option>
        <option value="NOUN_PHRASE">Noun phrase</option>
      </select>
    </div>
  }

  return <div
      className="d-flex"
      ref={props.innerRef}
      {...props.draggableProps}
    >
      <i style={{fontSize: "37px"}}
        {...props.dragHandleProps}
       className="bi bi-grip-vertical">
      </i>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={({ value }) => onSuggestionsFetchRequested(value)}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={(event,  { suggestion }) => handleSelectedSuggestion(suggestion)}
        renderSuggestion={renderSuggestion}
        shouldRenderSuggestions={() => true}
        inputProps={{
          className:"form-control form-control-lg",
          placeholder: "Barack Obama is",
          value: props.queryParams.query,
          onChange: (_, { newValue }) => handleQueryTextChange(newValue),
          required: true,
        }}
      />
    </div>
}

interface HeaderState {
  resultStatus: ResultState;
  suggestions: Array<QueryParams>;
}

interface HeaderProps extends SearchUpdateProps {
  resultStatus: ResultState;
}

export function Header(props: HeaderProps): JSX.Element {
  const [state, setState] = React.useState<HeaderState>({
    resultStatus: ResultState.Empty, suggestions: []});
  const [queryParams, setQueryParams] = React.useState<QueryParams>(props.queryParams);
  const initItems = [{'id': 'text-input', 'content': 'input'}, {'id': 'partOfSpeech-select', 'content': 'select'}];
  const [items, setItems] = React.useState<Array<Item>>(initItems);

  useEffect(() => {
    setState(previousState => ({...previousState, resultStatus: props.resultStatus}));
  }, [props.resultStatus]);

  useEffect(() => {
    setQueryParams(props.queryParams);
    if (props.queryParams.position == Position.BEFORE) {
      setItems(initItems.reverse());
    } else {
      setItems(initItems);
    }
  }, [props.queryParams]);

  function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    props.onUpdate(queryParams);
  }

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }
    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    const position = reorderedItems[0].id == "partOfSpeech-select" ? Position.BEFORE: Position.AFTER;
    setQueryParams({...queryParams, position: position});
    setItems(reorderedItems);
  }

  function getListStyle(isDraggingOver: boolean) {
    return {
      background: isDraggingOver ? '#CBD1DE' : 'rgba(241, 244, 248, 0.903)',
      padding: 5,
    };
  }

  function onQueryParamsUpdate(params: QueryParams) {
    setQueryParams({...queryParams, query: params.query, partOfSpeech: params.partOfSpeech});
  }

  return (
    <div className="py-3 py-md-4 mt-2 mt-md-4">
      <h1 className="display-1 fw-bold mb-4 pb-md-3">Search 1 <span className="text-primary">billion pages</span> <br/> on Amazon <span className="text-primary">S3</span></h1>
        <p className="lead mb-5 pb-md-2">Start a phrase and let the web complete it<br/> by a noun phrase or an adjective </p>
        <form onSubmit={handleSubmit}>
          <div className="d-grid gap-2 d-sm-flex mb-5 drag-n-drop">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      {items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided) => (
                            <DraggableInput
                              onUpdate={onQueryParamsUpdate}
                              queryParams={queryParams}
                              innerRef={provided.innerRef}
                              draggableProps={provided.draggableProps}
                              dragHandleProps={provided.dragHandleProps}
                              type={item.content}/>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
              </Droppable>
            </DragDropContext>
            <button 
              type="submit" 
              className="btn btn-primary btn-lg px-4 me-sm-3"
              disabled={state.resultStatus == ResultState.FirstFetch}
              >
              Go!
            </button>
          </div>
        </form>
      </div>
  )
}
