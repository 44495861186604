export enum PartOfSpeech {
  ADJECTIVE = "ADJECTIVE",
  NOUN_PHRASE = "NOUN_PHRASE"
}

export enum Position {
  AFTER = "AFTER",
  BEFORE = "BEFORE"
}

export interface QueryParams {
  query: string;
  partOfSpeech: PartOfSpeech;
  position: Position;
}

export function normalizeQueryText(query: string): string {
  return query.toLowerCase().replace(/[^a-z0-9]+/g, ' ');
}

export function toCacheKey(queryParams: QueryParams): string {
  return normalizeQueryText(queryParams.query) + "_" + templateKey(queryParams);
}

export function templateKey(queryParams: QueryParams): string {
  return queryParams.partOfSpeech + "_" + queryParams.position;
}
