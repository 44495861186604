import React, { useLayoutEffect, useState } from "react";
import ReactWordcloud, { MinMaxPair, Scale, Spiral, Word } from "react-wordcloud";
import { Position } from "../models/Query";
import { SearchResponse } from "../models/SearchResponse";
import { ResultState } from "../models/Status";
import { toUrlQueryParams } from "../utils";


// green "#16D18E"
// blue "#164AD1"
const TAG_CLOUD_OPTIONS = {
  colors: ["#OOOOO","#FF0026"], //"#FF0026"], // #16D18E"],
  enableTooltip: false,
  deterministic: true,
  fontFamily: "Sohne",
  fontSizes: [10, 60] as MinMaxPair,
  fontStyle: "normal",
  fontWeight: "bold",
  padding: 5,
  rotations: 3,
  rotationAngles: [0, 0] as MinMaxPair,
  scale: "log" as Scale,
  spiral: "archimedean" as Spiral,
  transitionDuration: 1000,
  enableOptimizations: true,
};

function useWidth() {
  const [width, setWidth] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return width;
}

const BASE_WCLOUD_WIDTH = 800;

export function Tagcloud(props: {response: SearchResponse, resultState: ResultState}): JSX.Element {
  // select only the first 100 as the word cloud will very probably show nothing useful above 100 keywords
  const width = useWidth();
  const selectedOccurrences = props.response.occurrences.slice(0, 100);
  const mostFrequentOccurrence = selectedOccurrences[0];
  const topTenPctWords = selectedOccurrences
    .map(occurrence => occurrence[0])
    .slice(0, Math.max(1, Math.round(0.1 * selectedOccurrences.length)));
  const rwWords = selectedOccurrences.map(occurrence => {
    return {text: occurrence[0], value: occurrence[1]};
  });
  function getWordColor(word: Word) {
    if (topTenPctWords.includes(word.text)) {
      return "#FF0026";
    } else {
      return "#OOOOO";
    }
  }
  let headline, tweetHeadline;
  if (props.response.queryParams.position == Position.BEFORE) {
    headline = <div className="display-6 fw-bold mb-3"> <span className="text-primary">{mostFrequentOccurrence[0]}</span> {props.response.queryParams.query}.</div>;
    tweetHeadline = `« ${mostFrequentOccurrence[0]}... ${props.response.queryParams.query}. »`;
  } else {
    headline = <div className="display-6 fw-bold mb-3">{props.response.queryParams.query} <span className="text-primary">{mostFrequentOccurrence[0]}.</span></div>;
    tweetHeadline = `« ${props.response.queryParams.query}... ${mostFrequentOccurrence[0]}. »`;
  }

  const params = toUrlQueryParams(props.response.queryParams);
  const shareText = `${tweetHeadline} Pattern extracted from 1 billion web pages with #Quickwit search engine #Search #S3`;
  const shareUrl = `https://common-crawl.quickwit.io/?${params.toString()}`;
  const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}&via=Quickwit_inc`;
  const linkedinTitle = "Search 1 billion web pages on Amazon S3";
  const linkedinLink = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(linkedinTitle)}&summary=${encodeURIComponent(shareText)}&source=quickwit.io`;

  const scale = Math.min(width, BASE_WCLOUD_WIDTH) / BASE_WCLOUD_WIDTH;
  const options =  {
    ...TAG_CLOUD_OPTIONS,
    fontSizes: [10 * scale, 60 * scale] as MinMaxPair,
  };
  const maxWords = Math.floor(rwWords.length * scale);
  const words = rwWords.slice(0, maxWords);

  return (
    <div id="tagcloud">
      {headline}
      {props.resultState == ResultState.Partial && 
      <p>
        Still querying... 
      </p>
      }
      <div className="MainTagCloud mt-5 mb-5">
        <ReactWordcloud
            callbacks={{getWordColor: getWordColor}}
            options={options}
            words={words}/>
      </div>
      <div className="share mb-4">
        Share the result:
      </div>
      <div className="d-grid gap-2 d-sm-flex mb-5">
        <a type="button" className="btn btn-share btn-lg" href={twitterLink} target="_blank">
          <i className="bi bi-twitter me-3" style={{color: "#1DA1F2"}}></i>
          <span>Twitter</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="ms-2 me-2 bi bi-arrow-up-right ms-auto" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
          </svg>
        </a>
        <a type="button" className="btn btn-share btn-lg" href={linkedinLink} target="_blank">
          <i className="bi bi-linkedin me-3" style={{color: "#0A66C2"}}></i>
          <span>Linkedin</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="ms-2 me-2 bi bi-arrow-up-right ms-auto" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
          </svg>
        </a>
      </div>
      <div>
        <p>Discover how it works on our <a href="https://quickwit.io/blog/commoncrawl" target="_blank" className="link-primary fw-bold"> blog <i className="bi bi-chevron-right small"></i></a></p>
      </div>
    </div>
  )
}
